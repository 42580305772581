import React from "react";
import "./NoPermissions.scss";
import travolta from "../../images/travolta.gif";
export const NoPermission = () => {
  return (
    <div className="permission">
      <h2 className="permission__title">
        Try to reload page or get permission for viewing projects
      </h2>
      <img src={travolta} alt="travolta" className="permission__img" />
    </div>
  );
};
