/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { dayAgo } from './helpers/dates';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyBenetm6cgL7_AmG8RCSKdYPXt5RDELQds',
  authDomain: 'digis-tick.firebaseapp.com',
  projectId: 'digis-tick',
  storageBucket: 'digis-tick.appspot.com',
  messagingSenderId: '615110129879',
  appId: '1:615110129879:web:06a31db11460e50cd81892',
});

export const auth = app.auth();
export const db = app.firestore();

export const getAllUsers = async () => {
  const users = [];

  const querySnapshot = await db.collection('project-users').get();

  querySnapshot.forEach((doc) => {
    users.push({
      projectId: doc.id,
      users: doc.data().users,
    });
  });

  return users;
};

export const handleAddUserToDb = async (projectId, userData, callback) => {
  const docRef = db.collection('project-users').doc(projectId.toString());

  try {
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();
      if (data.users && Array.isArray(data.users)) {
        // users exists and is an array, add new value to it
        await docRef.update({
          users: firebase.firestore.FieldValue.arrayUnion(userData),
        });
      } else {
        // users does not exist or is not an array, create new array with first value
        await docRef.set(
          {
            users: [userData],
          },
          { merge: true }
        );
      }
    } else {
      // doc doesn't exist, create new document with array field
      await docRef.set({
        users: [userData],
      });
    }

    callback(projectId, userData);
  } catch (error) {
    console.log('Error getting document:', error);
  }
};

export const handleRemoveUserFromDb = async (projectId, userData, callback) => {
  const docRef = db.collection('project-users').doc(projectId.toString());

  try {
    await docRef.update({
      users: firebase.firestore.FieldValue.arrayRemove(userData),
    });

    callback(projectId, userData);
  } catch (error) {
    console.log('Error removing user from db:', error);
  }
};

export const handleUpdateUserInDb = async (
  projectId,
  objectToRemove,
  fieldsToUpdate,
  callback
) => {
  const docRef = db.collection('project-users').doc(projectId.toString());

  // Retrieve the document and update the users array
  const doc = await docRef.get();
  const oldArray = doc.data().users;

  const newArray = oldArray.map((obj) => {
    if (obj.id == objectToRemove.id) {
      // Replace the object with the updated object
      return {
        ...obj,
        ...fieldsToUpdate,
      };
    } else {
      return obj;
    }
  });

  // Use a batch write to ensure atomic updates
  const batch = db.batch();
  batch.update(docRef, { users: firebase.firestore.FieldValue.arrayRemove(objectToRemove) });
  batch.update(docRef, { users: newArray });
  await batch.commit();

  callback(projectId, newArray);
};

export const getUnloggedUsers = async (time = dayAgo) => {
  const docRef = db.collection('unlogged-users').doc(time);

  try {
    const doc = await docRef.get();

    if (doc.exists) {
      return {
        date: doc.id,
        users: doc.data().users,
      };
    } else {
      console.log('No such document!');
    }
  } catch (error) {
    console.log('Error getting document:', error);
  }
};

export const handleRemoveUnloggedUser = async (userToRemove, callback) => {
  const docRef = db.collection('unlogged-users').doc(dayAgo);

  try {
    await docRef.update({
      users: firebase.firestore.FieldValue.arrayRemove(userToRemove),
    });

    callback(userToRemove.id);
  } catch (error) {
    if (error.code === 'not-found') {
      console.log('Not found in unlogged error: ', { error });
    }

    console.error('Error removing user from db:', {error});
  }
};

export default app;
