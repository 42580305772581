/* eslint-disable */
export const blacklistedUser = (usersData, userId, projectId) => {
  return usersData?.find(
    (data) =>
      data.projectId == projectId &&
      data.users?.find((user) => user?.id == userId && user.isBlacklisted)
  );
};

export const userInDb = (usersData, userId, projectId) =>
  usersData
    ?.find((data) => data.projectId == projectId)
    ?.users?.find((user) => user.id == userId);
