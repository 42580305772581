import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Sidebar from '../Sidebar/Sidebar';
import { NoPermission } from '../NoPermission/NoPermission';
import { Project } from '../Project/Project';
import { UsersModal } from '../UsersModal/UsersModal';
import { getData, postEntries, refreshUnloggedUsers } from '../../api';
import { useAuth } from '../../contexts/AuthContext';
import { getAllUsers, getUnloggedUsers } from '../../firebase';
import {
  dayAgo,
  isMondayToday,
  isSundayToday,
  lastFriday,
  today,
} from '../../helpers/dates';
import { blacklistedUser } from '../../helpers/firebase';
import Loader from '../Loader/Loader';
import './Projects.scss';

const Projects = () => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentDay, setCurrentDay] = useState(today);
  const [calendarDay, setCalendarDay] = useState(new Date());
  const [isSideBarVisible, setSideBarVisible] = useState(false);
  const [isReload, setIsReload] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [unloggedUsers, setUnloggedUsers] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [digisData, setDigisData] = useState(null);
  const [doItData, setDoItData] = useState(null);

  const handleSideBarClick = () => {
    setSideBarVisible((prevState) => !prevState);
  };

  const handleDayClick = (day) => {
    const currentDate = day.toLocaleDateString('en-US').split('/');
    const newDay = `${currentDate[2]}-${currentDate[0].padStart(
      2,
      '0'
    )}-${currentDate[1].padStart(2, '0')}`;
    setCurrentDay(newDay);
    setCalendarDay(day);
    handleSideBarClick();
  };

  const fetchAndUpdateData = async () => {
    setIsLoading(true);

    try {
      const userDoItEmail =
        currentUser?.email === 'd.petrakovskyi@digiscorp.com'
          ? 'd.petrakovskyi@do-it.co'
          : currentUser?.email;

      if (!isSundayToday) {
        if (
          (!isMondayToday && currentDay === dayAgo) ||
          (isMondayToday && currentDay === lastFriday)
        ) {
          const [
            receivedDigisData,
            receivedDoItData,
            dbUsers,
            dbUnloggedUsers,
          ] = await Promise.all([
            getData('digis', currentUser?.email, currentDay),
            getData('doit', userDoItEmail, currentDay),
            getAllUsers(),
            getUnloggedUsers(isMondayToday ? lastFriday : dayAgo),
          ]);

          setDoItData(receivedDoItData);
          setDigisData(receivedDigisData);
          setUsersData(dbUsers);
          setUnloggedUsers(dbUnloggedUsers);
          return;
        }
      }

      const [receivedDigisData, receivedDoItData] = await Promise.all([
        getData('digis', currentUser?.email, currentDay),
        getData('doit', userDoItEmail, currentDay),
      ]);

      setDoItData(receivedDoItData);
      setDigisData(receivedDigisData);
    } catch (error) {
      toast.error('Failed to fetch project data.');
      console.error('Error fetching project data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser?.email) {
      fetchAndUpdateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDay, currentUser]);

  const handleAddToUsersState = (projectId, userObj) => {
    setUsersData((prevState) => {
      const projectIndex = prevState.findIndex(
        (record) => +record?.projectId === +projectId
      );
      if (projectIndex === -1) {
        return [...prevState, { projectId: +projectId, users: [userObj] }];
      } else {
        const updatedUsers = [...prevState[projectIndex].users, userObj];
        return prevState.map((record, index) =>
          index === projectIndex ? { ...record, users: updatedUsers } : record
        );
      }
    });
  };

  const handleRemoveFromUsersState = (projectId, userObj) => {
    setUsersData((prevState) => {
      return prevState.map((record) =>
        +record.projectId === +projectId
          ? {
              ...record,
              users: record.users.filter((user) => +user.id !== +userObj.id),
            }
          : record
      );
    });
  };

  const handleUpdateUsersState = (projectId, updatedData) => {
    setUsersData((prevState) =>
      prevState.map((record) =>
        +record.projectId === +projectId
          ? { ...record, users: updatedData }
          : record
      )
    );
  };

  const handleRemoveUnloggedUser = (userId) => {
    setUnloggedUsers((prevState) => ({
      ...prevState,
      users: prevState.users.filter((u) => +u.id !== +userId),
    }));
  };

  const handleSetProjectId = (projectId) => {
    setSelectedProjectId(projectId);
  };

  const handleRefreshUnloggedUsers = async () => {
    try {
      toast.loading('Refreshing...');
      await refreshUnloggedUsers({
        date: isMondayToday ? lastFriday : dayAgo,
        type: 'doit',
      });
      await fetchAndUpdateData();

      toast.dismiss();
      toast.success('Refreshed successfully');
    } catch (error) {
      toast.dismiss();
      toast.error('Failed to refresh unlogged users.');
      console.error('Error refreshing unlogged users:', error);
    }
  };

  const handleMoveUsersToWhiteTick = async (
    event,
    { project, currentDigisProject }
  ) => {
    event.preventDefault();

    if (doItData && digisData) {
      const filteredEntriesToSend = doItData.entries.filter((entry) => {
        return (
          +entry.project_id === +event.target.id &&
          !blacklistedUser(usersData, entry.entryDetails.user_id, project.id)
        );
      });

      if (filteredEntriesToSend.length >= 1) {
        filteredEntriesToSend.forEach((entry) => {
          const currentDoitUser = doItData.users.find(
            (user) => entry.entryDetails.user_id === user.id
          );

          const currentDigisUser = digisData.users.find(
            (user) =>
              user.email.split('@')[0].toLowerCase() ===
                currentDoitUser?.email?.split('@')?.[0]?.toLowerCase() ||
              (user.first_name.toLowerCase().trim() ===
                currentDoitUser?.first_name?.toLowerCase()?.trim() &&
                user.last_name.toLowerCase().trim() ===
                  currentDoitUser?.last_name?.toLowerCase()?.trim())
          );

          const currTask = digisData.tasks.find(
            (task) =>
              task.project_id === currentDigisProject.id &&
              task.name.toLowerCase().slice(0, 2) ===
                entry.task_name.toLowerCase().slice(0, 2)
          );

          const duplicate = digisData.entries.some(
            (entry) =>
              (entry.entryDetails.user_id === currentDigisUser.id &&
                entry.entryDetails.date === currentDay &&
                entry.project_id === currentDigisProject.id) ||
              (entry.entryDetails.user_id === currentDigisUser.id &&
                entry.project_id === currentDigisProject.id &&
                filteredEntriesToSend.some(
                  (digisEntry) =>
                    digisEntry.entryDetails.notes.toLowerCase().trim() ===
                    entry.entryDetails.notes.toLowerCase().trim()
                ))
          );

          if (currTask && currentDigisUser && !duplicate) {
            const data = {
              type: 'digis',
              date: currentDay,
              hours: entry.entryDetails.hours,
              notes: entry.entryDetails.notes,
              task_id: currTask.id,
              user_id: currentDigisUser.id,
            };

            postEntries(data).then(() => {
              fetchAndUpdateData();
            });
          }
        });
      }
    } else {
      toast.error('Data is not ready yet.');
    }
  };

  const handleMoveUserToWhiteTick = (event, { entry, currentDigisProject }) => {
    event.preventDefault();
    event.stopPropagation();

    console.log({ entry, currentDigisProject });

    if (doItData?.users?.length > 0 && digisData?.users?.length > 0) {
      const filteredEntriesToSend = [entry];

      if (filteredEntriesToSend.length >= 1) {
        filteredEntriesToSend.forEach((entry) => {
          const currentDoitUser = doItData.users.find(
            (user) => entry.entryDetails.user_id === user.id
          );

          const currentDigisUser = digisData.users.find(
            (user) =>
              user.email.split('@')[0].toLowerCase() ===
                currentDoitUser.email.split('@')[0].toLowerCase() ||
              (user.first_name.toLowerCase().trim() ===
                currentDoitUser.first_name.toLowerCase().trim() &&
                user.last_name.toLowerCase().trim() ===
                  currentDoitUser.last_name.toLowerCase().trim())
          );

          const currTask = digisData.tasks.find(
            (task) =>
              task.project_id === currentDigisProject.id &&
              task.name.toLowerCase().slice(0, 2) ===
                entry.task_name.toLowerCase().slice(0, 2)
          );

          const duplicate = digisData.entries.some(
            (entry) =>
              (entry.entryDetails.user_id === currentDigisUser.id &&
                entry.entryDetails.date === currentDay &&
                entry.project_id === currentDigisProject.id) ||
              (entry.entryDetails.user_id === currentDigisUser.id &&
                entry.project_id === currentDigisProject.id &&
                filteredEntriesToSend.some(
                  (digisEntry) =>
                    digisEntry.entryDetails.notes.toLowerCase().trim() ===
                    entry.entryDetails.notes.toLowerCase().trim()
                ))
          );

          if (duplicate) {
            alert('Duplicate data is discovered!');
            return;
          }

          if (currTask && currentDigisUser && !duplicate) {
            const data = {
              type: 'digis',
              date: currentDay,
              hours: entry.entryDetails.hours,
              notes: entry.entryDetails.notes,
              task_id: currTask.id,
              user_id: currentDigisUser.id,
            };

            const confirmation = window.confirm(
              `Confirm transfer selected entry with Task: ${data.notes}, Time: ${data.hours}.`
            );

            if (!confirmation) return;

            postEntries(data).then(() => {
              fetchAndUpdateData();
            });
          }
        });
      }
    } else {
      toast.error('Data is not ready yet.');
    }
  };

  const handleEntryChange = ({ id, value, type, doItData }) => {
    const changedEntries = doItData.entries.map((entry) => {
      if (entry.entryDetails.id === id) {
        return {
          ...entry,
          entryDetails: { ...entry.entryDetails, [type]: value },
        };
      }
      return entry;
    });

    setDoItData((prevData) => ({ ...prevData, entries: changedEntries }));
  };

  return (
    <>
      <div className="projectsBoard">
        <div className="projectsBoard__container">
          <Sidebar
            handleDayClick={handleDayClick}
            calendarDay={calendarDay}
            isSideBarVisible={isSideBarVisible}
            handleSideBarClick={handleSideBarClick}
          />
          {isLoading ? (
            <Loader />
          ) : doItData?.projects?.length > 0 &&
            digisData?.projects?.length > 0 ? (
            <div className="projectsBoard__body">
              {doItData.projects
                .filter((project) =>
                  digisData.projects.some((digisProject) =>
                    digisProject.name
                      .toLowerCase()
                      .includes(project.name.toLowerCase())
                  )
                )
                .map((project) => {
                  const currentDigisProject = digisData.projects.find(
                    (digisProject) =>
                      digisProject.name
                        .toLowerCase()
                        .includes(project.name.toLowerCase())
                  );
                  return (
                    <Project
                      key={`${project.id}-${project.name}`}
                      project={project}
                      digisData={digisData}
                      doItData={doItData}
                      handleEntryChange={handleEntryChange}
                      currDay={currentDay}
                      setIsReload={setIsReload}
                      isReload={isReload}
                      currentDigisProject={currentDigisProject}
                      usersData={usersData}
                      unloggedUsers={unloggedUsers}
                      handleMoveUsersToWhiteTick={handleMoveUsersToWhiteTick}
                      handleMoveUserToWhiteTick={handleMoveUserToWhiteTick}
                      refreshUnloggedUsers={handleRefreshUnloggedUsers}
                      addToUsersState={handleAddToUsersState}
                      updateUsersState={handleUpdateUsersState}
                      setSelectedProjectId={handleSetProjectId}
                    />
                  );
                })}
            </div>
          ) : (
            <NoPermission />
          )}
        </div>
      </div>
      <UsersModal
        showModal={!!selectedProjectId}
        onClose={() => handleSetProjectId('')}
        projectId={selectedProjectId}
        usersData={usersData}
        updateUsersState={handleUpdateUsersState}
        removeFromUsersState={handleRemoveFromUsersState}
        unloggedUsers={unloggedUsers}
        removeUnloggedUser={handleRemoveUnloggedUser}
      />
    </>
  );
};

export default Projects;
