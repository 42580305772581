import { useState } from "react";

export const useCbOnce = (cb) => {
  const [called, setCalled] = useState(false);

  return (e) => {
      if (!called) {
          setCalled(true);
          cb(e);
      }
  }
}