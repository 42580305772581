import { useEffect, useRef, useState } from 'react';
import './Dropdown.scss';

export const Dropdown = ({ onSelect, options = [], children }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('click', handleOutsideClick, true);
      return () => {
        document.removeEventListener('click', handleOutsideClick, true);
      };
    }
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown__container">
      <span className='dropdown__anchor' onClick={toggleDropdown}>{children}</span>
      {isOpen && (
        <div className="dropdown__menu">
          {options.map((option) => (
            <button
              type="button"
              key={option}
              className="dropdown__button"
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
