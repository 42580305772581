import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import { Dropdown } from '../Dropdown/Dropdown';
import { dayAgo } from '../../helpers/dates';
import { blacklistedUser, userInDb } from '../../helpers/firebase';
import {
  handleRemoveUnloggedUser,
  handleRemoveUserFromDb,
  handleUpdateUserInDb,
} from '../../firebase';
import { clearOption } from '../../helpers/common';

const generateOption = (action, userName, actionPlace) => {
  return `${action} ${userName}${actionPlace ? ' ' + actionPlace : ''}`;
};

export const UsersModal = ({
  showModal,
  onClose,
  projectId,
  usersData,
  updateUsersState,
  removeFromUsersState,
  unloggedUsers,
  removeUnloggedUser,
}) => {
  const projectWithUsers =
    usersData?.length > 0 &&
    usersData.find((record) => +record.projectId === +projectId);

  const handleOptionSelect = async (option, userData) => {
    const name = userData.fullName?.split(' ')[0]?.toLowerCase()?.trim();

    switch (clearOption(option)) {
      case `add ${name} to blacklist`:
        handleUpdateUserInDb(
          projectId,
          userInDb(usersData, userData.id, projectId),
          {
            ...userInDb(usersData, userData.id, projectId),
            isBlacklisted: true,
          },
          updateUsersState
        );
        break;
      case `add ${name} to whitelist`:
        handleUpdateUserInDb(
          projectId,
          userInDb(usersData, userData.id, projectId),
          {
            ...userInDb(usersData, userData.id, projectId),
            isBlacklisted: false,
          },
          updateUsersState
        );
        break;
      case `skip ${name}`:
        await Promise.all([
          handleUpdateUserInDb(
            projectId,
            userInDb(usersData, userData.id, projectId),
            { skipped: dayAgo },
            updateUsersState
          ),

          handleRemoveUnloggedUser(
            unloggedUsers.users.find(
              (user) => +user.id === +userData.id
            ),
            removeUnloggedUser
          ),
        ]);

        toast.success('Skipped');
        break;
      case `remove ${name} from database`:
        handleRemoveUnloggedUser(
          userInDb(usersData, userData.id, projectId),
          removeUnloggedUser
        );
        handleRemoveUserFromDb(
          projectId,
          userInDb(usersData, userData.id, projectId),
          removeFromUsersState
        );
        break;
      default:
        console.log('Default');
        return;
    }
  };

  const getDropdownOptions = (userId, name) => {
    return [
      blacklistedUser(usersData, userId, projectId)
        ? generateOption('Add', name, 'to whitelist')
        : generateOption('Add', name, 'to blacklist'),
      generateOption('Skip', name),
      generateOption('Remove', name, 'from database'),
    ];
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {projectWithUsers?.users?.map((user) => (
          <div key={user.id}>
            <Dropdown
              options={getDropdownOptions(user.id, user.fullName?.split(' ')?.[0])}
              onSelect={(option) => {
                handleOptionSelect(option, {
                  id: user.id,
                  fullName: user.fullName,
                });
              }}
            >
              {user.fullName}
            </Dropdown>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};
