/* eslint-disable */
import React from "react";
import { Toaster } from 'react-hot-toast'
import { Signup } from "./components/Auth/Signup";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserDashBoard } from "./components/Auth/UserDashBoard";
import { Login } from "./components/Auth/Login";
import PrivateRoute from "./PrivateRoute";
import { ForgotPassword } from "./components/Auth/ForgotPassword";
import { UpdateProfile } from "./components/Auth/UpdateProfile";
import Projects from "./components/Projects/Projects";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/" component={UserDashBoard} />
            <PrivateRoute path="/update-profile" component={UpdateProfile} />
            <PrivateRoute path="/projects" component={Projects} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
          </Switch>
        </AuthProvider>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
