export const clearOption = (option) => {
  return option.toLowerCase().trim().split(' ').filter(Boolean).join(' ');
}

export const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}