import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Icons } from '../Icons/Icons';
import { Dropdown } from '../Dropdown/Dropdown';
import { dayAgo, isMondayToday, lastFriday } from '../../helpers/dates';
import { clearOption } from '../../helpers/common';
import { useCbOnce } from '../../hooks/useCbOnce';
import { blacklistedUser, userInDb } from '../../helpers/firebase';
import { handleAddUserToDb, handleUpdateUserInDb } from '../../firebase';

import './Project.scss';

const generateOption = (action, userName, actionPlace) => {
  return `${action} ${userName}${actionPlace ? ' ' + actionPlace : ''}`;
};

export const Project = ({
  project,
  digisData,
  doItData,
  currentDigisProject,
  handleEntryChange,
  currDay,
  usersData,
  unloggedUsers,
  handleMoveUsersToWhiteTick,
  handleMoveUserToWhiteTick,
  refreshUnloggedUsers,
  addToUsersState,
  updateUsersState,
  setSelectedProjectId,
}) => {
  const handleOptionSelect = async (option, userData) => {
    const name = userData.fullName.split(' ')[0].toLowerCase().trim();

    switch (clearOption(option)) {
      case `add ${name} to blacklist`:
        handleUpdateUserInDb(
          project.id,
          userInDb(usersData, userData.id, project.id),
          { isBlacklisted: true },
          updateUsersState
        );
        break;
      case `add ${name} to whitelist`:
        handleUpdateUserInDb(
          project.id,
          userInDb(usersData, userData.id, project.id),
          { isBlacklisted: false },
          updateUsersState
        );
        break;
      case `add ${name} to database`:
        handleAddUserToDb(project.id, userData, addToUsersState);
        break;
      default:
        return;
    }
  };

  const projectUnloggedUsers = unloggedUsers?.users?.filter(
    (user) => +user.projectId === +project.id
  );

  const unloggedUserNames = projectUnloggedUsers
    ?.map((user) => user.fullName)
    ?.join(', ');

  const getDropdownOptions = (userId, name) => {
    return !userInDb(usersData, userId, project.id)
      ? [generateOption('Add', name, 'to database')]
      : [
          blacklistedUser(usersData, userId, project.id)
            ? generateOption('Add', name, 'to whitelist')
            : generateOption('Add', name, 'to blacklist'),
        ];
  };

  return (
    <form
      className="projectIt"
      onSubmit={useCbOnce((event) =>
        handleMoveUsersToWhiteTick(event, {
          project,
          currentDigisProject,
        })
      )}
      id={project.id}
    >
      <div className="projectIt__body">
        <span className="projectsBoard__body--it">DO-IT</span>
        <div className="projectIt__title">
          {project.name}

          <div className="projectIt__title--right">
            {((isMondayToday && currDay === lastFriday) ||
              (!isMondayToday && currDay === dayAgo)) && (
              <Icons.Refresh
                className="projectIt__title__icon"
                onClick={refreshUnloggedUsers}
              />
            )}

            {projectUnloggedUsers?.length > 0 &&
              ((isMondayToday && currDay === lastFriday) ||
                (!isMondayToday && currDay === dayAgo)) && (
                <span
                  className="projectIt__title__indicator"
                  data-for="unlogged"
                  data-tip={`${unloggedUsers.date}: ${unloggedUserNames}`}
                >
                  {projectUnloggedUsers?.length}
                </span>
              )}

            <Icons.BurgerMenu
              className="projectIt__title__icon"
              onClick={() => setSelectedProjectId(project.id)}
            />

            <ReactTooltip
              key={projectUnloggedUsers?.length}
              id="unlogged"
              multiline={true}
            />
          </div>
        </div>
        <div className="projectIt__top">
          <span className="projectIt__headers projectIt__headers--medium">
            User
          </span>
          <span className="projectIt__headers projectIt__headers--small">
            Time
          </span>
          <span className="projectIt__headers projectIt__headers--large">
            Task
          </span>
        </div>
        <ul className="projectIt__tasks">
          {doItData.entries.length
            ? doItData.entries.map((entry) => {
                const user = doItData.users.find(
                  (user) => entry.entryDetails.user_id === user?.id
                );
                return (
                  entry.project_id === project?.id && (
                    <li
                      className={`projectIt__task ${
                        blacklistedUser(usersData, user?.id, project?.id)
                          ? 'projectIt__task--blacklisted'
                          : ''
                      }`}
                      key={entry.entryDetails?.id}
                    >
                      <span className="projectIt__task projectIt__task--user">
                        {user ? (
                          <Dropdown
                            options={getDropdownOptions(
                              user?.id,
                              user?.first_name
                            )}
                            onSelect={(option) => {
                              handleOptionSelect(option, {
                                id: user?.id,
                                fullName: `${user?.first_name} ${user?.last_name}`,
                                isBlacklisted: false,
                                projectId: project?.id,
                                projectName: project?.name,
                              });
                            }}
                          >
                            {user?.first_name + ' ' + user?.last_name}
                          </Dropdown>
                        ) : (
                          'Deleted user'
                        )}
                      </span>

                      <input
                        className="projectIt__task projectIt__task--hours"
                        value={entry.entryDetails.hours}
                        onChange={(event) =>
                          handleEntryChange({
                            id: entry.entryDetails.id,
                            value: event.target.value,
                            type: 'hours',
                            doItData,
                          })
                        }
                      />
                      <textarea
                        className="projectIt__task projectIt__task--notes"
                        value={entry.entryDetails.notes}
                        onChange={(event) =>
                          handleEntryChange({
                            id: entry.entryDetails.id,
                            value: event.target.value,
                            type: 'notes',
                            doItData,
                          })
                        }
                      />
                      {/* If user is blacklisted, show the arrow */}
                      {!blacklistedUser(usersData, user?.id, project?.id) && (
                        <button
                          data-tip="Transfer single entry data"
                          className="projectIt__button--personalEntry"
                          onClick={(e) =>
                            handleMoveUserToWhiteTick(e, {
                              entry,
                              currentDigisProject,
                            })
                          }
                        />
                      )}

                      <ReactTooltip
                        className="personalEntryTooltip"
                        multiline={true}
                      />
                    </li>
                  )
                );
              })
            : null}
        </ul>
      </div>
      <button
        className="projectIt__button"
        type="submit"
        data-tip="Transfer all project data"
        data-for="transfer-data"
      />
      <ReactTooltip
        id="transfer-data"
        className="personalEntryTooltip"
        multiline={true}
      />
      <div className="projectDigis__body">
        <span className="projectsBoard__body--digis">DIGIS</span>
        <div className="projectDigis__title">{project.name}</div>
        <div className="projectDigis__top">
          <span className="projectDigis__headers projectDigis__headers--medium">
            User
          </span>
          <span className="projectDigis__headers projectDigis__headers--small">
            Time
          </span>
          <span className="projectDigis__headers projectDigis__headers--large">
            Task
          </span>
        </div>
        <ul
          className="projectDigis__tasks"
          key={`${project.id}-${project.name}`}
        >
          {digisData.entries.length > 0 &&
            digisData.entries
              .filter((entry) => entry.project_id === currentDigisProject.id)
              .map((entry) => {
                const user = digisData.users.find(
                  (user) => entry.entryDetails.user_id === user.id
                );

                return (
                  <li
                    className="projectDigis__task"
                    key={`${entry.entryDetails.id}-${entry.project_id}`}
                  >
                    <span className="projectDigis__task projectDigis__task--user">
                      {user
                        ? `${user.first_name} ${user.last_name}`
                        : 'Deleted user'}
                    </span>
                    <span className="projectDigis__task projectDigis__task--hours">
                      {entry.entryDetails.hours}
                    </span>
                    <span className="projectDigis__task projectDigis__task--notes">
                      {entry.entryDetails.notes}
                    </span>
                  </li>
                );
              })}
        </ul>
      </div>
    </form>
  );
};
