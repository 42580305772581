import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import DayPicker from "react-day-picker";
import "./SideBar.scss";
import "./DayPicker.scss";
import logo from "../../images/logo.png";

const Sidebar = ({
  handleSideBarClick,
  calendarDay,
  handleDayClick,
  isSideBarVisible,
}) => {
  const { logout } = useAuth();
  const history = useHistory();
  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      console.log("Failed to log out");
    }
  }
  return (
    <div className="side-bar">
      <button
        className="side-bar__button"
        onClick={() => handleSideBarClick()}
      />
      {isSideBarVisible && (
        <div className="side-bar__mobile">
          <img alt="digis-logo" src={logo} className="side-bar__logo" />
          <div>
            <DayPicker
              onDayClick={handleDayClick}
              selectedDays={calendarDay}
              className="side-bar__calendar"
            />
          </div>
          <a href="/" className="side-bar__link">
            Profile
          </a>
          <button className="side-bar__logout" onClick={handleLogout}>
            Logout
          </button>
        </div>
      )}
      <div className="side-bar__desktop">
        <img alt="digis-logo" src={logo} className="side-bar__logo" />
        <div>
          <DayPicker
            onDayClick={handleDayClick}
            selectedDays={calendarDay}
            className="side-bar__calendar"
          />
        </div>
        <a href="/" className="side-bar__link">
          Profile
        </a>
        <button className="side-bar__logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
